import React from "react";
import {formAbandonment} from "@helpers/formAbandonment";
import { navigate } from "gatsby";

import { MenuProvider } from "./src/contextModels/MenuContext";
import { ModalProvider } from "./src/contextModels/ModalContext";

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
        speed: 500,
        speedAsDuration: true,
        offset: 100,
        easing: 'easeInOutCubic'
    })
}

export const wrapRootElement = ({ element }) => (
    <ModalProvider>
        <MenuProvider>{element}</MenuProvider>
    </ModalProvider>
);

export const onClientEntry = () => {
    const pathName = window.location.pathname;
    const langCode = pathName.split('/').filter(url => url.length === 2);

    if (['en', 'ja'].includes(langCode[0]) || pathName === '/') {
        return;
    }

    // ToDo: Get default country environment variables.
    if (pathName !== '/' && typeof navigator.language !== 'undefined' && ['en', 'ja'].includes(langCode[0])) {
        const language = navigator.language.split('-')[0].toLowerCase();
        navigate(`/${language}${pathName}`);
    }
    else if(!['en', 'ja'].includes(langCode[0])) {
        // ToDo: Get default country environment variables.
        navigate(`/ja${pathName}`);
    }
}

/**
 * @description Called when the initial (but not subsequent) render of Gatsby App is done on the client.
 */
export const onInitialClientRender = () => {
    // Netlify Identity Widget
    if (window.netlifyIdentity) {
        window.netlifyIdentity.on("init", user => {
            if (!user) {
                window.netlifyIdentity.on("login", () => {
                    document.location.href = "/admin/";
                });
            }
        })
    }
}

/**
 * @param  {object} prevLocation - Object object of previously visited page.
 * @param  {string} prevLocation.pathname - String of previously visited page.
 * @description Pushed to the datalayer if leaving a form page before submitting.
 */
export const onPreRouteUpdate = ({ prevLocation }) => {
    formAbandonment({prevLocation});
}

/**
 * @param {Object} pageData - Object of the default objects.
 * @param {Object} pageData.location - A location object.
 * @param {Object} pageData.prevLocation - The previous location object.
 * @description Every time the user changes route, we push an object to the data layer.
 */
export const onRouteUpdate = (pageData) => {
    (async () => {
        setTimeout(async () => {

            // Deconstrucing the object.
            const { prevLocation } = pageData;

            // Add previous origin to locations object.
            window.locations = window.locations || [document.referrer];
            window.locations.push(window.location.origin);
            window.previousOrigin = window.locations[window.locations.length - 2];

            const url = window.location.href;
            const baseUrl = window.location.origin;
            const newUrl = url.replace(baseUrl, '');
            const pageTitle = document.title;
            let pageType = 'General Page';
            const pageLanguage = document.documentElement.lang;
            const dataLayer = window.dataLayer || [];
            const previousPage = prevLocation ? prevLocation.pathname : "";

            // Changes the pagetype based on the URL.
            switch (newUrl) {
            case '/':
                pageType = 'Homepage';
                break;
            case '/products':
                pageType = 'Product Overview Page';
                break;
            case '/contact':
                pageType = 'Contact';
                break;
            case '/book-a-demo':
                pageType = 'Book a Demo Page';
                break;
            case '/blog':
                pageType = 'Blog Overview Page';
                break;
            case '/support':
                pageType = 'Support';
                break;
            case '/about':
                pageType = 'About Page';
                break;
            default:
                if (newUrl.startsWith('/case-study')) {
                    pageType = 'Case Study';
                }
                else if (newUrl.startsWith('/products/')) {
                    pageType = 'Individual Product Page';
                }
                else if (newUrl.startsWith('/blog/')) {
                    pageType = 'Blog Article';
                }
            }

            // ToDo: Add functionality to switch the Language.

            dataLayer.push({
                event: 'page_load',
                previous_page: previousPage,
                page_type: pageType,
                page: pageTitle,
                language: pageLanguage
            });
        }, 500);
    })()
};

export const shouldUpdateScroll = () => {
    window.scrollTo({
        top: 0,
        behavior: 'instant'
    })
    return false
}
