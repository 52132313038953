import React, { createContext, useContext, useState } from "react";

const MenuContext = createContext([{}, () => {}]);

const MenuProvider = props => {
    const [state, setState] = useState({
        isMenuActive: false
    });

    const {children} = props

    return (
        <MenuContext.Provider value={[state, setState]}>
            {children}
        </MenuContext.Provider>
    );
};

const useMenuHandles = () => {
    const [state, setState] = useContext(MenuContext);

    function toggleMenu(e) {
        e.preventDefault();
        // eslint-disable-next-line
        setState(prevState => ({ ...prevState, isMenuActive: !prevState.isMenuActive }));
    }

    function closeMenu() {
        // eslint-disable-next-line
        setState(state => ({ ...state, isMenuActive: false }));
    }

    return {
        toggleMenu,
        closeMenu,
        isMenuActive: state.isMenuActive
    };
};

export { MenuContext, MenuProvider, useMenuHandles };
