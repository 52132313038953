const formAbandonment = ({prevLocation}) => {
    const dataLayer = window.dataLayer || [];

    if (prevLocation && prevLocation.pathname.includes('demo') || prevLocation && prevLocation.pathname.includes('contact')) {
        // Gets length of forms array.
        const arrayLength = window.MktoForms2.allForms().length;
        // Gets most recently interacted with form.
        const latestForm = window.MktoForms2.allForms()[arrayLength - 1];
        // Gets all values of most recently interacted with form.
        const allFormValues = latestForm.getValues();

        dataLayer.push({
            // Gets form id of most recently interacted with form.
            event: `${latestForm.getId()} - Form Abandonment`,
            content: allFormValues
        });
    }
}

// eslint-disable-next-line import/prefer-default-export
export {formAbandonment};
